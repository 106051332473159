/**
 * Returns an array of { key, value } objects for the specified enum type.
 * Note that it assumes the enum type has values that are either numbers or
 * strings that exactly match the keys.
 *
 * @param enumType - The enum to retrieve the key-valu pairs from
 * @returns The key-value pairs of the enum
 */
 export function getEnumKeyValuePairs(enumType: Record<string, any>): Array<{ key: string; value: any }> {
    return Object.getOwnPropertyNames(enumType)
        .filter(key => isNaN(parseInt(key, 10)))
        .map(key => ({
            key,
            value: enumType[key],
        }));
}
