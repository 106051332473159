import copy from 'fast-copy';
/**
 * Fast deep cloning function that can be used on client or server.
 *
 * @param value - The value to deep clone
 * @returns The clone
 */
 export function clone<T>(value: T): T {
    return copy(value);
}

