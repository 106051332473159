import { validators } from '@deltasierra/class-validation';
import { InterfaceOf } from '@deltasierra/type-utilities';

export class TemplateBuilderConfig {
    @(validators.string().required())
    public socialIconsCloudfrontUrl: string;

    public constructor(input: InterfaceOf<TemplateBuilderConfig>) {
        this.socialIconsCloudfrontUrl = input.socialIconsCloudfrontUrl;
    }
}
