'use client';

/* eslint-disable node/no-process-env */
import { validateAsClass } from '@deltasierra/class-validation';
import { RecursivePartial } from '@deltasierra/type-utilities';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { AppConfigContext } from '../../contexts';
import { AppConfig } from '../../types';

type PartialAppConfig = RecursivePartial<AppConfig>;

async function fetchAppConfig(): Promise<AppConfig | null> {
    try {
        // Load the config file asynchronously (if it exists)
        const result = await fetch('/app.config.json');
        return (await result.json()) as AppConfig;
    } catch (error) {
        // We can ignore these errors if the config doesn't exist because we are probably in dev
        return null;
    }
}

function validateAppConfig(potential: PartialAppConfig): AppConfig {
    const result = validateAsClass(AppConfig, potential);
    if (!result.success) {
        throw result.failure;
    }
    return result.value;
}

async function fetchAndValidateAppConfig(): Promise<AppConfig> {
    const fetchedAppConfig = await fetchAppConfig();
    const validatedAppConfig = validateAppConfig({
        // Add config from the environment (for dev) here
        appWebUrl: process.env.NEXT_PUBLIC_DS_APP_WEB_URL,
        googleTagManagerId: process.env.NEXT_PUBLIC_DS_GOOGLE_TAG_MANAGER_ID,
        templateBuilder: {
            socialIconsCloudfrontUrl: process.env.NEXT_PUBLIC_DS_TEMPLATE_BUILDER_SOCIAL_ICONS_CLOUDFRONT_URL,
        },
        // Override with fetched appConfig
        ...fetchedAppConfig ?? {},
    });
    return validatedAppConfig;
}

type AppConfigProviderProps = {
    children: ReactNode;
};

export function AppConfigProvider({ children }: AppConfigProviderProps): JSX.Element {
    const [appConfig, setAppConfig] = useState<AppConfig | null>(null);
    const [error, setError] = useState<unknown>(null);

    // eslint-disable-next-line consistent-return
    const loadAppConfig = useCallback(async () => {
        try {
            const validatedAppConfig = await fetchAndValidateAppConfig();
            return setAppConfig(validatedAppConfig);
        } catch (err) {
            setError(err);
        }
    }, [setAppConfig]);

    useEffect(() => {
        void loadAppConfig();
    }, [loadAppConfig]);

    useEffect(() => {
        if (error === null) {
            return;
        }
        throw error;
    }, [error]);

    return <AppConfigContext.Provider value={appConfig}>{children}</AppConfigContext.Provider>;
}

AppConfigProvider.displayName = 'AppConfigProvider';
