import { validators } from '@deltasierra/class-validation';
import { InterfaceOf } from '@deltasierra/type-utilities';
import { TemplateBuilderConfig } from './template-builder.config';

export class AppFrontendConfig {
    @(validators.string().required())
    public appWebUrl: string;

    @(validators.object().required())
    public templateBuilder: TemplateBuilderConfig;

    @(validators.string().optional())
    public googleTagManagerId: string | undefined;

    public constructor(input: InterfaceOf<AppFrontendConfig>) {
        this.appWebUrl = input.appWebUrl;
        this.templateBuilder = input.templateBuilder;
        this.googleTagManagerId = input.googleTagManagerId;
    }
}
