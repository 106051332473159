'use client';

import { ReactNode } from 'react';
import { useAppConfig } from '../../hooks';

type AppConfigRequiredWrapperProps = {
    children: ReactNode;
};

export function AppConfigRequiredWrapper({ children }: AppConfigRequiredWrapperProps): JSX.Element | null {
    const appConfig = useAppConfig(false);

    if (!appConfig) {
        return null;
    }

    return <>{children}</>;
}
AppConfigRequiredWrapper.displayName = 'AppConfigRequiredWrapper';
