import * as jf from 'joiful';
import { mergeDeep } from '@deltasierra/object-utilities';
import { ClassType } from '@deltasierra/type-utilities';
import { ValidationResult, ValidationErrorType } from './types';

/**
 * Validates an object as a class
 *
 * @param clazz - The class we want
 * @param obj - The object input
 * @param defaultValues - A helper for specifying default values
 * @returns - The result of the validation
 */
export function validateAsClass<T = unknown>(
    clazz: ClassType<T>,
    obj: Partial<T>,
    defaultValues?: Partial<T>,
): ValidationResult<ValidationErrorType, T>;
export function validateAsClass<T = unknown>(
    clazz: ClassType<T>,
    obj: any,
    defaultValues?: Partial<T>,
): ValidationResult<ValidationErrorType, T>;
export function validateAsClass<T = unknown>(
    clazz: ClassType<T>,
    obj: any,
    defaultValues?: Partial<T>,
): ValidationResult<ValidationErrorType, T> {
    const inputObject = mergeDeep<Partial<T>>(defaultValues ?? {}, obj ?? {});

    const validationResult = jf.validateAsClass(inputObject, clazz, {
        abortEarly: false,
        allowUnknown: true,
        convert: true,
        stripUnknown: true,
    });

    if (validationResult.error) {
        return {
            failure: validationResult.error,
            failureValue: validationResult.value,
            success: false,
        };
    } else {
        return {
            success: true,
            value: validationResult.value,
        };
    }
}
