'use client';

import { GoogleTagManager } from '@next/third-parties/google';
import { useAppConfig } from '../../hooks';

/**
 * This component provides the loader for the GoogleTagManager script.
 * It should be placed right before the body tag so it is loaded immediately.
 */
export function ProvideGoogleTagManagerScript(): JSX.Element {
    const appConfig = useAppConfig(false);

    if (!appConfig || !appConfig.googleTagManagerId) {
        return <></>;
    }
    return <GoogleTagManager gtmId={appConfig.googleTagManagerId} />;
}
ProvideGoogleTagManagerScript.displayName = 'ProvideGoogleTagManagerScript';
