import { clone } from './clone';

export interface MergeDeepOptions {
    skipNull?: boolean;
}

export function mergeDeep<T extends object>(..._objects: T[]): T {
    return mergeDeepWithOptions({}, ..._objects);
}

export function mergeDeepWithOptions<T extends object>(options: MergeDeepOptions, ..._objects: T[]): T {
    const { skipNull = false } = options;

    const output: any = {};
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const objects = _objects.map(object => object || {});

    // Enumerate the objects and their keys.
    for (const object of objects) {
        // For each key in the object
        for (const key of Object.keys(object)) {
            const value = object[key as keyof typeof object];
            const outputKey = key as keyof typeof output;
            // If the value is not undefined undefined,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (typeof value !== 'undefined' && (!skipNull || value !== null)) {
                // If its key is an object
                if (typeof value === 'object') {
                    if (Array.isArray(value)) {
                        output[outputKey] = [...Array.isArray(output[outputKey]) ? output[outputKey] : [], ...value];
                    } else {
                        // If we already have a value
                        const clonedValues = clone(value);
                        const existingValue = typeof output[outputKey] === 'object' ? output[outputKey] : {};
                        output[outputKey] = output[outputKey]
                            ? mergeDeepWithOptions(options, {}, existingValue, clonedValues)
                            : clonedValues;
                    }
                } else {
                    output[outputKey] = value;
                }
            }
        }
    }
    return output as T;
}
