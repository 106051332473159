
export function lastIndexOf<T>(array: T[], predicate: (elem: T, index?: number) => boolean): number {
    let result = -1;

    for (let i = array.length; i--;) {
        if (predicate(array[i], i)) {
            result = i;
            break;
        }
    }

    return result;
}
