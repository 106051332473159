/**
 * Returns the first found string value that's not an object for a given input object
 * Uses the search key to find the value you're looking for
 * This is only recommended for shallow objects, as it's a recursive search
 *
 * @param {Record<string, unknown>} data - The object
 * @param {string} searchKey - The search key
 * @returns {string | false} - If key is found it'll return a string, if not it will return false
 */
export const findFirstStringValue = (data: Record<string, unknown>, searchKey: string = 'message'): string | false => {
    for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'string' && key === searchKey) {
            return value;
        }
        if (typeof value === 'object' && value !== null) {
            const nestedValue = findFirstStringValue(value as Record<string, unknown>, searchKey);
            if (nestedValue) {
                return nestedValue;
            }
        }
    }

    return false;
};
