import { getEnumKeyValuePairs } from './get-enum-key-value-pairs';

/**
 * Returns an array of values for the specified enum type.
 * Note that it assumes the enum type has values that are either numbers or
 * strings that exactly match the keys.
 *
 * @param enumType - The enum to retrieve the values from
 * @returns The value of the given enum
 */
 export function getEnumValues(enumType: Record<string, any>): any[] {
    return getEnumKeyValuePairs(enumType).map(pair => pair.value);
}

